.searchInp {    
    width: 40%;
    border-width: thin;
    padding-left: 5px;
    margin-left: -12px;
}
.imgSearchInput{
    position: absolute;
    margin-top: 7px;
    margin-left: -5px;
}

.filters {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 450px;
    margin-top: 30px;
    margin-left: -12px;
    z-index: 10;
    position: absolute;
    border-style: solid;
    border-width: thin;
    background-color: white;
    visibility: inherit;
}

.acceptButton {
    flex: 150px;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
}

.filterElement {
    flex: 150px;
    text-align: center;
    margin-top: 15px;
    margin-left: 15px;
}

.filterButton {
    text-align: center;
    margin-top: 15px;
    margin-right: 15px;
    max-width: 200px;
    min-width: 200px;
}