.waitWindow{
    display: flex;
    flex-wrap: wrap;
   
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    z-index: 20;
    margin-top: 63px;
    width: 100%;
    height: 100%;
    visibility: inherit;
}
.waitWord{
    margin-top: 30px;
    margin-left: 30px;
    color: white;
    
    

}
