.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  flex-grow: 1; 
}

.btn-play {
  height: 15px;
  width: 10px;
  border-style: solid;
  border-width: 10px 0 10px 15px;
  border-color: transparent transparent transparent #ffffff;
  cursor: pointer;
}
.btn-stop {
  height: 15px;
  width: 12px;
  border-left: 2px solid rgb(255, 255, 255);
  border-right: 2px solid rgb(255, 255, 255);
  cursor: pointer;
}
.move{  
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.download{  
  width: 20px;
  height: 20px;
  cursor: pointer;

}