.buttonLeft {
    margin-top: 2px;
    margin-left: 60px;

}

.buttonMiddle {
    margin-top: 2px;
    margin-left: 5px;
}

.buttonRight {
    margin-top: 2px;
    margin-left: 20px;

}