.mainMenuCol {
    width: 20%;
    height: 100%;
    position: fixed;
    z-index: 2;
    background: linear-gradient(0, rgb(22, 50, 133) 14%, rgb(19, 102, 179) 100%);
}

.mainWindowCol {
    width: 100%;
    background-color: rgb(255, 255, 255);
    z-index: 1;
    padding-left: 20%;
}

.mainPageRow {
    height: 100vh;
    position: relative;
}

.mainMenuButton {
    height: auto;
    margin-top: 15vh;
    margin-left: -12px;
    width: 100%;
    background: rgba(21, 36, 119, 0.158);
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: left;
    text-decoration: none;
    display: inline-block;
}

.mainMenuExitButton {
    height: auto;
    margin-bottom: 2vh;
    margin-left: -15px;
    width: 100%;
    background: rgba(21, 36, 119, 0.158);
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: left;
    text-decoration: none;

}

.mainMenuButton:hover {

    background: rgb(21, 36, 119);
    color: white;
}

.brand {
    margin-left: 15px;
    text-align: left;
    color: aliceblue;

}

.callsName {
    margin-top: 20vh;
    margin-left: 15px;
    text-align: left;
    color: aliceblue
}