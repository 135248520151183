.window {
    width: 101%;
    height: 400px;
    overflow: auto;
}

.th {
    text-align: center;
    font-size: small;
}

.td {
    text-align: center;
    font-size: small;
}

.container {
    display: flex;
}

.imgC {
    margin-left: 3px;
    cursor: pointer;
}

.callTextC {
    width: 80%;
    text-align: center;
    font-size: small;
    font-style: normal;
}
.callGroup{
    width: 80%;
    text-align: center;
    font-size: small;
    font-style: normal;

}