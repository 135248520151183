.th {
    text-align: center;
    font-size: small;    
}

.td {
    text-align: center;
    font-size: small;
}

.span {
    cursor: pointer;
    font-size: small;
    padding: 0px;
    width: 85%;
    
}
.count{
    text-align: center;
    font-size: small;
    width: 15%;
}
.num{
    font-size: small;
    width: 85%;
}

.img {
    margin-left: 10px;
    cursor: pointer;
}

.callText {
    width: 80%;
    margin-left: 15px;
    text-align: center;
    font-size: small;
    font-style: normal;

}

.container {
    display: flex;
}
.windowClientCalls{
    min-width: 35%;
    max-width: 600px;
}
