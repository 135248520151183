.InputHeaderOutput {
    font-size: large;
    color: brown;
    font-size: 22px;
    margin-left: 5px;
    vertical-align: middle;
}

.InputHeaderFiltersOutput {
    cursor: pointer;
    font-size: 10px;
    width: 100px;
    height: 15px;
    margin-left: 5px;
    margin-top: 10px;
    vertical-align: middle;
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 3px;
    
}



.containerHeaderInputCallsOutput {
    display: flex;
    text-align: center;
    vertical-align: middle;

}