.th {
    text-align: center;
    font-size: small;
}

.td {
    text-align: center;
    font-size: small;
}

.span {
    cursor: pointer;
    font-size: small;
    display: inline-block;


    padding: 0px;
}

.img {
    margin-left: 10px;
    cursor: pointer;
    

}

.callText {
    width: 80%;
    margin-left: 15px;
    text-align: center;
    font-size: small;
    font-style: normal;

}

.container {
    display: flex;
}
.windowClientCalls{
    min-width: 35%;
    max-width: 600px;
}