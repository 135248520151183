.control-panel {
  padding: 1px 0;
  display: flex;
  justify-content: space-between;
}

.timer {
  font-size: 12px;
  font: bold;
  color: rgb(255, 255, 255);
}

